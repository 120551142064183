import type { RouteRecordRaw } from "vue-router";
import Home from "@/views/home/HomeView.vue";

const getRedirect = function () {
  if (!process.env.VUE_APP_START) return "/home";
  else if (process.env.VUE_APP_START == "guide") return "/guide";
  else return "/home";
};

export default <RouteRecordRaw[]>[
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/guide",
    name: "Guide",
    // component: () => import("@/views/guide/GuideView.vue"),
    // component: () => import("@/views/guide/GuideNewView.vue"),
    component: () => import("@/views/guide/GuideView2.vue"),
    meta: {
      title: APP_NAME,
      hideFixedHome: true,
      spaceKeys: ["Guide_banner", "Guide_native"]
    }
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/HomeViewNew.vue"),
    meta: {
      title: APP_NAME,
      hideFixedHome: true
    }
  },
  {
    path: "/historyDetail",
    name: "HistoryDetail",
    component: () => import(/* webpackPrefetch: true  */ "@/views/history/HistoryDetailView.vue"),
    props: (route) => ({ id: route.query.id }),
    meta: {
      title: "历史详情"
    }
  },
  // {
  //   path: "/home",
  //   component: Home,
  //   meta: {
  //     title: APP_NAME,
  //     hideFixedHome: true
  //   },
  //   children: [
  //     {
  //       path: "",
  //       name: "Home",
  //       component: () => import(/* webpackPrefetch: true  */ "@/views/home/sub-views/HomeLookView.vue"),
  //       meta: {
  //         title: "今日一看"
  //       }
  //     },
  //     {
  //       path: "history",
  //       name: "HomeHistory",
  //       component: () => import(/* webpackPrefetch: true  */ "@/views/home/sub-views/HomeHistoryView.vue"),
  //       meta: {
  //         title: "历史大事件"
  //       }
  //     },
  //     {
  //       path: "weather",
  //       name: "HomeWeather",
  //       component: () => import(/* webpackPrefetch: true  */ "@/views/home/sub-views/HomeWeatherView.vue"),
  //       meta: {
  //         title: "天气预测"
  //       }
  //     }
  //   ]
  // },
  {
    path: "/historyDetail",
    name: "HistoryDetail",
    component: () => import(/* webpackPrefetch: true  */ "@/views/history/HistoryDetailView.vue"),
    props: (route) => ({ id: route.query.id }),
    meta: {
      title: "历史详情"
    }
  },
  {
    path: "/historyList",
    name: "HistoryList",
    component: () => import(/* webpackPrefetch: true  */ "@/views/history/HistoryListView.vue"),
    meta: {
      title: "历史上的今天"
    }
  },
  {
    path: "/holidaysList",
    name: "HolidaysList",
    component: () => import(/* webpackPrefetch: true  */ "@/views/holidays/HolidaysListView.vue"),
    meta: {
      title: "节假日"
    }
  },
  {
    path: "/huangliDetail",
    name: "HuangliDetail",
    component: () => import(/* webpackPrefetch: true  */ "@/views/huangli/HuangliDetailView.vue"),
    props: (route) => ({ date: route.query.date }),
    meta: {
      title: "黄历"
    }
  },
  {
    path: "/weatherDetail",
    name: "WeatherDetail",
    component: () => import(/* webpackPrefetch: true  */ "@/views/weather/WeatherDetailView.vue"),
    props: (route) => ({ city: route.query.city, code: route.query.city }),
    meta: {
      title: "7日天气"
    }
  },
  {
    path: "/topicMulti",
    name: "TopicMulti",
    component: () => import(/* webpackPrefetch: true  */ "@/views/topic/TopicMultiView.vue"),
    meta: {
      title: "历史大挑战"
    }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/NotFoundView.vue"),
    meta: {
      title: "404"
    }
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404"
  }
];
